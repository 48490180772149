<template>
  <div>
    <b-form-group
      label=""
      label-for="select-editors"
      label-cols="0"
      label-cols-sm="0"
      label-cols-md="0"
      class="m-0 p-0"
    >
      <b-input-group class="mb-1">
        <b-form-input
          v-model="input_search"
          type="search"
          size="sm"
          :placeholder="`Buscar ${$getVisibleNames(
            'teaching.evaluationcriteriamacro',
            true,
            'Criterios de Evaluación Macro'
          )}`"
        >
        </b-form-input>
        <b-input-group-append>
          <b-button
            :disabled="!input_search"
            @click="input_search = ''"
            size="sm"
            >Limpiar</b-button
          >
        </b-input-group-append>
      </b-input-group>
      <b-form-select
        v-model="evaluation_criterias_ids"
        size="sm"
        class="select-form"
        multiple
      >
        <option
          v-for="item in fillEvaluationsCriteriasList"
          :key="item.id"
          :value="item.id"
          class="select-option-macro"
        >
          {{ item.order }} - {{ item.full_sentence }}
        </option></b-form-select
      >
    </b-form-group>
    <div class="col" style="text-align: right">
      <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "EvaluationCriteriasMacro",
  props: {
    EvaluationCriterias: {
      type: Array,
    },
    Section: {
      type: Object,
    },
    Options: {
      type: Array,
    },
  },
  data() {
    return {
      input_search: "",
      evaluation_criterias_ids: this.EvaluationCriterias,
    };
  },
  computed: {
    fillEvaluationsCriteriasList: function () {
      return this.Options.filter((item) => {
        return (
          this.$filtered(String(item.full_sentence), this.input_search) ||
          this.evaluation_criterias_ids.includes(item.id)
        );
      }).sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return a.full_sentence
          .toLowerCase()
          .localeCompare(b.full_sentence.toLowerCase());
      });
    },
  },
  methods: {
    save() {
      this.$emit("created", this.evaluation_criterias_ids, this.Section);
    },
  },
  created() {
    // this.EvaluationCriterias.forEach((element) => {
    //   if (!isNaN(element.id)) this.evaluation_criterias_ids.push(element.id);
    // });
  },
};
</script>

<style scoped>
.select-form {
  display: block !important;
}
.select-option-macro,
.select-option-micro {
  overflow-wrap: break-word;
  white-space: pre-wrap;
  padding: 0 0.25em;
  margin-left: 10px;
}
.select-option-micro {
  text-indent: -16px;
}
</style>